import { ErrorMessage } from "@hookform/error-message"
import clsx from "clsx"
import React, { useState, VFC } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import HelperText from "../form/HelperText"
import Input from "../form/Input"
import { auth } from "../../libs/nhost"
import { AuthFormProps } from "."
import { kmitlLoginSchema } from "./schemaValidation"
import { Loading } from "../ui/Loading"

export const KmitlLoginForm: VFC<AuthFormProps> = ({ onCompleted }) => {
  const [authError, setAuthError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(kmitlLoginSchema),
  })

  const onSubmit = handleSubmit(async (dataForm) => {
    try {
      setAuthError(null)
      setLoading(true)
      await auth.login(dataForm.email, dataForm.password)

      toast.success("เข้าสู่ระบบสำเร็จ")
      if (onCompleted) {
        onCompleted()
      }
    } catch (ex) {
      setAuthError("ไม่พบข้อมูล กรุณาลองใหม่อีกครั้ง")
    } finally {
      setLoading(false)
    }
  })

  return (
    <form onSubmit={onSubmit} noValidate>
      <div className="mb-4">
        <Input
          autoFocus
          id="email"
          label="รหัสนักศึกษาหรือชื่อผู้ใช้ (ไม่ใส่ @kmitl.ac.th)"
          placeholder="เช่น 60199999 หรือ rakthai.th"
          required
          {...register("email")}
          error={!!errors["email"]}
        />
        <ErrorMessage
          errors={errors}
          name="email"
          render={(data) => (
            <HelperText color="red-700">{data.message}</HelperText>
          )}
        />
      </div>
      <div className="mb-4">
        <Input
          id="password"
          label="รหัสผ่าน"
          placeholder="รหัสผ่าน"
          required
          type="password"
          error={!!errors["password"]}
          {...register("password")}
        />
        <ErrorMessage
          errors={errors}
          name="password"
          render={(data) => (
            <HelperText color="red-700">{data.message}</HelperText>
          )}
        />
      </div>
      {authError ? (
        <HelperText color="red-700" className="mb-4">
          {authError}
        </HelperText>
      ) : null}
      <div className="mt-4">
        <button
          className={clsx(
            `w-full flex justify-center px-4 py-2 border-2 shadow-sm text-base font-semibold rounded border-orange-600  text-orange-700 bg-white hover:bg-orange-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed`
          )}
          type="submit"
          disabled={loading}
        >
          {loading && <Loading />}
          เข้าสู่ระบบ
        </button>
      </div>
    </form>
  )
}
