import { ErrorMessage } from "@hookform/error-message"
import React, { VFC } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import HelperText from "../form/HelperText"
import Input from "../form/Input"
import { useAnonymous } from "../../store/anonymous"
import { AuthFormProps } from "."
import { anonymousLoginSchema } from "./schemaValidation"

export const AnonymousLoginForm: VFC<AuthFormProps> = ({ onCompleted }) => {
  const { login } = useAnonymous()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(anonymousLoginSchema),
  })

  const onSubmit = handleSubmit((dataForm) => {
    login({
      tel: dataForm.tel,
      email: dataForm.email,
      contact_name: dataForm.contact_name,
    })

    toast.success("เข้าสู่ระบบสำเร็จ")

    if (onCompleted) {
      onCompleted()
    }
  })

  return (
    <form onSubmit={onSubmit} noValidate>
      <div className="mb-4">
        <Input
          autoFocus
          id="tel"
          label="เบอร์ติดต่อ"
          placeholder="tel"
          required
          {...register("tel")}
          error={!!errors["tel"]}
        />
        <ErrorMessage
          errors={errors}
          name="tel"
          render={(data) => (
            <HelperText color="red-700">{data.message}</HelperText>
          )}
        />
      </div>
      <div className="mb-4">
        <Input
          id="email"
          label="Email"
          placeholder="email"
          {...register("email")}
        />
      </div>
      <div className="mb-4">
        <Input
          id="contact_name"
          label="ชื่อ-นามสกุล"
          placeholder="ชื่อ-นามสกุล"
          required
          {...register("contact_name")}
          error={!!errors["contact_name"]}
        />
        <ErrorMessage
          errors={errors}
          name="tel"
          render={(data) => (
            <HelperText color="red-700">{data.message}</HelperText>
          )}
        />
      </div>
      <div className="mt-4">
        <button
          className="w-full flex justify-center px-4 py-2 border-2 border-emerald-600 shadow-sm text-base font-semibold rounded text-emerald-700 bg-white hover:bg-emerald-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed"
          type="submit"
        >
          บันทึกข้อมูล
        </button>
      </div>
    </form>
  )
}
