import * as yup from "yup"

export const kmitlLoginSchema = yup
  .object()
  .shape({
    email: yup.string().required("กรุณาระบุรหัสนักศึกษาหรือชื่อผู้ใช้"),
    password: yup
      .string()
      .required("กรุณาระบุรหัสผ่าน")
      .min(4, "ความยาวรหัสผ่านไม่น้อยกว่า 4 ตัวอักษร"),
  })
  .required()

export const anonymousLoginSchema = yup
  .object()
  .shape({
    tel: yup.string().required("กรุณากรอกเบอร์ติดต่อ"),
    email: yup.string(),
    contact_name: yup.string().required("กรุณากรอกชื่อ-นามสกุล"),
  })
  .required()
