import React, { Fragment, useState, VFC } from "react"
import { AnonymousLoginForm, KmitlLoginForm } from "."

export interface AuthFormProps {
  onCompleted?: () => void
}

export const AuthForm: VFC<AuthFormProps> = ({ onCompleted }) => {
  const [type, setType] = useState<"kmitl" | "anonymous" | null>(null)

  return (
    <div className="mb-4 flex flex-col">
      {type === null ? (
        <Fragment>
          <div>
            <button
              onClick={() => setType("kmitl")}
              type="button"
              className="w-full flex justify-center px-4 py-2 border-2 border-orange-600 shadow-sm text-xl font-semibold rounded text-orange-700 bg-white hover:bg-orange-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              บุคลากรสจล. หรือนักศึกษาสจล.
            </button>
          </div>
          <div className="mt-12">
            <button
              onClick={() => setType("anonymous")}
              type="button"
              className="w-full flex justify-center px-4 py-2 border-2 border-emerald-600 shadow-sm text-xl font-semibold rounded text-emerald-700 bg-white hover:bg-emerald-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              บุคคลภายนอก
            </button>
          </div>
        </Fragment>
      ) : type === "kmitl" ? (
        <KmitlLoginForm onCompleted={onCompleted} />
      ) : (
        <AnonymousLoginForm onCompleted={onCompleted} />
      )}
      {type !== null ? (
        <div>
          <div className="mt-6 mb-6 relative">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">หรือ</span>
            </div>
          </div>
          <button
            onClick={() => setType(null)}
            type="button"
            className="w-full flex justify-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            ย้อนกลับ
          </button>
        </div>
      ) : null}
    </div>
  )
}
