import React from "react"
import Layout from "../components/layout/Layout"
import { Helmet } from "react-helmet"
import { AuthForm } from "../components/auth"
import { navigate } from "gatsby"

const LoginPage = () => {
  const handleSuccess = () => {
    navigate("/")
  }

  return (
    <Layout>
      <Helmet>
        <title>เข้าสู่ระบบ - GoFight | KMITL</title>
      </Helmet>
      <AuthForm onCompleted={handleSuccess} />
    </Layout>
  )
}

export default LoginPage
